import React, { Component } from 'react';
import './ProjectCard.css';
import 'hover.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

class ProjectCard extends Component {
    render() {
      return  <div data-aos="fade" data-aos-once="true" data-aos-duration="1000" className="projectCard">
                  <div className="left-box">
                    <div><a className="title" target='_blank' rel="noreferrer" href={this.props.link}>{this.props.name}</a></div>
                    <div className="info">{this.props.desc}</div>
                    <div className="tech bigTech">{this.props.tech.join(' • ')}</div>
                  </div>
                  <div className="workImage">
                    <a target='_blank' rel="noreferrer" href={this.props.link}><img className="hvr-shrink" src={this.props.image} alt={this.props.name} /></a>
                    <div className="tech smallTech">{this.props.tech.join(' • ')}</div>
                  </div>
                
             </div>
    }
  }
  
  export default ProjectCard;