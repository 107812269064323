import React, { Component } from 'react';
import './About.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import {ReactComponent as Instagram} from '../../assets/instagram.svg';
import {ReactComponent as Twitter} from '../../assets/twitter.svg';
import {ReactComponent as Linkedin} from '../../assets/linkedin.svg';
import {ReactComponent as Github} from '../../assets/github.svg';
import {ReactComponent as Gitlab} from '../../assets/gitlab.svg';

class About extends Component {
    render() {
      return <div className="About">
        <div data-aos="fade-in" data-aos-once="true" data-aos-duration="1500" className="AboutDesc">
                Born and raised in New Jersey. Educated at New Jersey Institute of Technology. Software engineer since 2013. Experienced building large and small scale applications for Fortune 500 companies, startups, and everything in between.
            <br />
            <br />
            Traveler. Nerd. Dancer. Continuously searching for the perfect Mojito. Hit me up and say Hi :)
            <br />
            <br />
                <div>
                    <a target='_blank' rel="noreferrer" href={"https://www.linkedin.com/in/mike-stramandinoli/"}>
                      <Linkedin className="icon" />
                    </a>
                    <a target='_blank' rel="noreferrer" href={"https://github.com/melkors-lammoth"}>
                      <Github className="icon" />
                    </a>
                    <a target='_blank' rel="noreferrer" href={"https://gitlab.com/melkors-lammoth"}>
                      <Gitlab className="icon" />
                    </a>
                    <a target='_blank' rel="noreferrer" href={"https://www.instagram.com/melkors_lammoth/"}>
                      <Instagram className="icon" />
                    </a>
                    <a target='_blank' rel="noreferrer" href={"https://twitter.com/melkors_lammoth"}>
                      <Twitter className="icon" />
                    </a>
                </div>
            </div>      
      </div>
    }
  }
  
  export default About;