import './App.css';
import BackgroundImage from './assets/seamless_paper_texture.png';
import Typewriter from 'typewriter-effect';
import {ReactComponent as DownArrow} from './assets/downArrow4.svg';
import {ReactComponent as BottomDivider} from './assets/shredded-paper-bottom-custom.svg';
import {ReactComponent as TopDivider} from './assets/shredded-paper-top-custom.svg';
import React, { useState, useEffect } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import Navbar from "./components/Navbar";
import Section from "./components/Section";
import billionminds from './assets/8bm.svg';
import leadpaw from './assets/lp.svg';
import billionmindsColor from './assets/8bm-color.svg';
import leadpawColor from './assets/lp-color.svg';
import ProjectCard from './components/ProjectCard/ProjectCard';
import Footer from "./components/Footer/Footer";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Scrollbars } from 'react-custom-scrollbars';

import About from './components/About/About';

function App() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);

  window.onbeforeunload = function () {
    setLoading(true);
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    document.body.classList.add('no-scroll');
    AOS.init();
    setTimeout(function(){ 
      setLoading(false);
      document.body.classList.remove('no-scroll');
    }, 3000);
  }, [])

  return (
    //<Scrollbars autoHide autoHeight universal>
      <div className="App">
      
        <div id="overlay" data-aos="fade-in" style={ { display: `${ show ? 'block' : 'none' }` } }>
          <About />
        </div>
        <div data-scroll-container className="data-scroll-container loading">
          
          <div className="content">
            <div className="header">
              <div className="nav-right">
                <h3 data-aos="fade-in" data-aos-once="true" data-aos-delay="3000" onClick={() => {
                  setShow(!show);
                  show ? document.body.classList.remove('no-scroll') : document.body.classList.add('no-scroll');
                }}>{` ${show ? 'Close' : 'About'}`}</h3>
              </div>
              <div className="nav-left">
                <h1 data-aos="fade-in" data-aos-delay="3000" data-aos-once="true" onClick={() =>  window.location.href='/'}>Mike Stram</h1>
              </div>
            </div>
          <div data-scroll-section className="appBackground backgroundTint" style={{
            backgroundImage: `url(${BackgroundImage})`,
            backgroundRepeat: "repeat"
          }}>
          <div style={ { display: `${ show ? 'none' : 'block' }` } }>
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .stop()
                  .typeString('Hi.')
                  .pauseFor(0)
                  .deleteAll()
                  .typeString("I'm Mike.")
                  .start();
              }}
              />
          </div>
            <BottomDivider data-aos="slide-up" data-aos-delay="3000" data-aos-duration="1500" className="bottomDivider" />
            <TopDivider data-aos="slide-down" data-aos-delay="3000" data-aos-duration="1500" className="topDivider" />
            <DownArrow className="downArrow" data-aos="fade-in" data-aos-delay="3000" data-aos-duration="1500" style={ { display: `${ show ? 'none' : 'block' }` } }/>
            </div>
          <div className="appBackground work">
            <div style={ { display: `${ show ? 'none' : 'block' }`, width: `100%`} }>
              <div data-aos="fade-in" data-aos-once="true" className="section"><h2>Some Projects I've Worked On</h2></div>
              <ProjectCard name="8 Billion Minds" image={billionminds} imageAlt={billionmindsColor} link={'https://www.8billionminds.com/'}
                desc="A web app that allows people from around the world to share, learn and teach each other." 
                tech={["JavaScript", "jQuery", "CSS", "Java", "Spring", "MySQL"]}/>
              <ProjectCard name="Leadpaw" image={leadpaw}  imageAlt={leadpawColor} link={'https://www.leadpaw.com/leadpaw/main2.php'}
                desc="A web app that connects pet owners with pet experts who can care for their pets."
                tech={["JavaScript", "jQuery", "CSS", "Bootstrap", "PHP", "MySQL", "PayPal API"]}/>
            </div>
          </div>
          <div className="footer" style={ { display: `${ show ? 'none' : 'block' }` } }>
            <p>Designed & Built by Michael Stramandinoli</p>
          </div>
        </div>
        
        
        </div>
        
      </div>
      //</Scrollbars>
  );
}

export default App;
