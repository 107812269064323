import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
    render() {
      return <div className="footer">
        <p>Designed & Built by Michael Stramandinoli</p>
      </div>;
    }
  }
  
  export default Footer;